var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('beenokle-form',{attrs:{"schema":_vm.activeItem,"exclude-fields":[
      'id', 
      'type', 
      'children', 
      'boundingBox.x', 
      'boundingBox.y',
      'draggable',
      'background',
      'parentId'
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }