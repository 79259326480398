<template>
  <div>
    <beenokle-form 
      :schema="activeItem"
      :exclude-fields="[
        'id', 
        'type', 
        'children', 
        'boundingBox.x', 
        'boundingBox.y',
        'draggable',
        'background',
        'parentId'
      ]"
    />
    <!-- <properties-field 
      class="b-scene-inspector__property"
      v-for="(prop) in items"
      :key="`prop-${prop.id}`"
      :item-data="prop"
      :active-item="activeItem"
      @input="val => $emit('update:propertyVal', { val, activeItem })"
    /> -->
  </div>
</template>
<script>

import BeenokleForm from '@/components/beenokle-form'
//import PropertiesField from './field'

export default {
  props: {
    activeItem: {
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  name: 'BeenokleSceneInspectorProperties',
  components: {
    BeenokleForm
    //PropertiesField
  }
}
</script>