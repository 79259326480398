<template>
  <div
    v-if="scene"
    class="b-scene-inspector"
  >
    <div 
      class="pa-5 px-0 quote-edit__block ma-0 flex-grow-1"
      style="max-height: 50%; overflow: hidden;"
    >
      <v-subheader class="pa-0 pb-4" style="height: auto;">Structure</v-subheader>
      <scene-inspector-structure
        style="max-height: 100%; overflow: auto;"
        class="b-scene-inspector__structure custom-scroll"  
        v-model="scene.model"
        :active-item="activeItem"
        @update:activeItem="onClick" 
      />
    </div>
    <div
      class="ma-0 mt-5 quote-edit__block pa-5 pr-0 custom-scroll flex-grow-1"
      style="max-height: 50%; overflow: hidden;"
    >
      <v-subheader class="pa-0 pb-4" style="height: auto;">Properties</v-subheader>
      <scene-inspector-properties
        v-if="scene && activeItem"
        style="max-height: 100%; overflow: auto;"
        class="b-scene-inspector__properties custom-scroll pr-5"
        :items="editablePropsList"
        :active-item="activeItem"
        @update:propertyVal="onUpdateProperty"
      />
    </div>
  </div>
</template>
<script>
import SceneInspectorStructure from './structure/index'
import SceneInspectorProperties from './properties/index'

export default {
  props: {
    scene: {
      required: true
    },
    value: {
      type: Array
    }
  },
  name: 'BeenokleSceneInspector',
  components: {
    SceneInspectorStructure,
    SceneInspectorProperties
  },
  data: () => ({
    activeItem: null
  }),
  created() {
    this.onClick(this.scene.model[0])
  },
  computed: {
    editablePropsList() {
      let result = []

      // this.activeItem && Object.keys(this.activeItem).forEach(i => {
      //     console.log(i)
      //     const finded = this.editableProps.find(p => p.id === i)
      //     finded && result.push(finded)
      //   })

      return result
    }
  },
  methods: {
    onUpdateProperty({ activeItem }) {
      const { id } = activeItem
      this.scene.needRerenderItem = id
      
      this.$nextTick(() => {
        this.scene.needRerenderItem = null
      })
    },
    getByPath(path) {
      let temp = this.scene.model

      path.forEach((i, index) => {
        temp = index === 0 ? temp[0] : temp.children[i]
      })

      return temp
    }, 
    onClick(item) {
      this.activeItem = item
    }
  }
}
</script>
<style lang="scss">
.tree-node {
  &--active {
    color:red;
  }
}
</style>