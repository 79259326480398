<template>
  <form
    v-if='schema'
    class="beenokle-form"
  > 
    <form-field 
      v-for="schemaField in editableSchemaFields"
      :key="`field-${schemaField}`"
      :field-data="schemaField"
      :item-data="getItemData(schemaField)"
      @update:schemaValue="onUpdateSchema"
    />
  </form>
</template>
<script>
import FormField from './field'

export default {
  props: {
    excludeFields: {
      type: Array,
      default: () => []
    },
    schema: {
      type: Object,
      required: true
    }
  },
  name: 'BeenokleForm',
  components: {
    FormField
  },
  computed: {
    editableSchemaFields() {
      const keys = this.getAllKeysFromSchema(this.schema).filter(f => !this.excludeFields.includes(f[0]))
      return keys
    }
  },
  methods: {
    onUpdateSchema({ path, val }) {
      let schema = this.schema
      let pList = path.split('.');
      let len = pList.length
      for(var i = 0; i < len-1; i++) {
          var elem = pList[i];
          if( !schema[elem] ) schema[elem] = {}
          schema = schema[elem]
      }

      schema[pList[len-1]] = val
    },
    getItemData(field) {
      const splited = field[0].split('.')
      let tempVal = this.schema
      
      splited.forEach(part => {
        tempVal = tempVal[part]
      })

      return tempVal
    },
    getAllKeysFromSchema(obj = {}) {
      let keys = []
      for (let key in obj) {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          var subkeys = this.getAllKeysFromSchema(obj[key])
          keys = keys.concat(subkeys.map((subkey) => {
              const path = key + "." + subkey[0]
              const result = [path, typeof obj[key][subkey[0]]]
              return [...result, this.getItemData(result)]
          }))
        } else {
          keys.push([key, typeof obj[key], this.getItemData(key)])
        }
      }

      return keys
    }
  }
}
</script>