<template>
  <div>
    <structure-item
      class="col-8" 
      v-model="model"
      :active-item="activeItem"
      :item-data="model[0]"
      @update:activeItem="onUpdateActiveItem"
    />
  </div>
</template>
<script>
import StructureItem from './item'

export default {
  props: {
    value: {},
    activeItem: {}
  },
  name: 'BeenokleSceneInspectorStructure',
  components: {
    StructureItem
  },
  created() {
    console.log('model: ', this.model)
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onUpdateActiveItem(item) {
      this.$emit('update:activeItem', item)
    }
  }
}
</script>