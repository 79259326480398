<template>
  <div
    class="beenokle-form__field"
  >
    <template
      v-if="fieldData[1] === 'string' || fieldData[1] === 'number'"
    >
      <v-text-field
        :type="fieldData[1] === 'string' ? 'text' : 'number'"
        :value="model"
        @input="onInput"
      />
    </template>
    <div>
      <div class="caption">
        Field data: {{ fieldData }}
      </div>
      <div>
        Value: {{ model }}
      </div>
    </div>
    <v-divider class="my-3" />
  </div>
</template>
<script>
export default {
  props: {
    itemData: {
      required: true
    },
    fieldData: {
      required: true
    }
  },
  name: 'BeenokleFormField',
  computed: {
    model: {
      get() {
        return parseInt(this.itemData)
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onInput(val) {
      const [path ] = this.fieldData

      this.$emit('update:schemaValue', {
        path,
        val
      })
    }
  }
}
</script>