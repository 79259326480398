<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="pa-0 w-100"
    style="width: 100%; max-width: 100%;"
    :list="list"
    :value="value"
    @input="emitter"
  >
    <v-list-item
      dense
      v-on.stop="el.id === 'canvas' ? {} : { click: () => onClick(el) }"
      :input-value="activeItem && activeItem.id === el.id"
      class="structure-item d-flex align-center"
      :class="[el.id === 'canvas' ? 'px-0' : 'px-2']"
      :key="el.id" 
      v-for="el in realValue"
    >
      <v-list-item-icon
        v-if="el.icon"
        style="margin-right: 8px !important;"
        class="pl-0 pt-0 align-self-center justify-center"
      >
        <v-icon
          size="16"
        >
          {{ el.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content
        class="py-0"
      >
        <v-list-item-title
          class="d-flex align-center"
          :style="{
            minHeight: el.id === 'canvas' && '34px'
          }"
        >
          {{ el.id }}
        </v-list-item-title>
        <structure-item 
          class="structure-item__children" 
          :list="el.children" 
          :item-data="el"
          :active-item="activeItem"
          @update:activeItem="i => $emit('update:activeItem', i)"
        />
      </v-list-item-content>
      <v-menu
        left
        offset-y
        v-if="el.id !== 'canvas'"
      >
        <template v-slot:activator="{on}">
          <v-btn
            icon
            width="24"
            height="24"
            v-on.stop="on"
          >
            <v-icon
              size="16"
            >
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>
        <v-list
          dense
          min-width="200"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Item 1
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
  </draggable>
</template>
<script>
import draggable from "vuedraggable"

export default {
  props: {
    itemData: {
      type: Object,
      required: true
    },
    activeItem: {

    },
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    }
  },
  name: 'structure-item',
  components: {
    draggable
  },
  computed: {    
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    realValue() {
      const result = this.value ? this.value : this.list
      console.log(result)
      return result
    }
  },
  methods: {
    onClick(e) {
      this.$emit('update:activeItem', e)
    },
    emitter(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss">
.structure-item {
  min-height: 34px !important;
}
</style>